import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { HeaderH3 } from './shared/Header';

export default function MenuList({ menulist, lang }) {
  const [menulistOne, setMenuListOne] = useState([]);
  const [menulistTwo, setMenuListTwo] = useState([]);

  useEffect(() => {
    if (menulist[0] !== undefined) {
      setMenuListOne(menulist[0].menuSectionsOne);
      setMenuListTwo(menulist[0].menuSectionsTwo);
    }
  }, [menulist]);

  return (
    <MenuListContainer>
      <ColumnOne>
        {menulistOne &&
          menulistOne.map((menu, i) => (
            <DishesContainer key={i}>
              <HeaderH3>{menu[`heading${lang}`]}</HeaderH3>
              <Dishes>
                {menu.menuItems.map((dish, dishIndex) => (
                  <Dish key={dishIndex}>
                    <NameAndAllergenWrapper>
                      <DishName>{dish[`name${lang}`]}</DishName>
                      {dish[`allergens${lang}`].map(
                        (allergen, allergenIndex) => (
                          <Allergen key={allergenIndex}>
                            {allergen.split(' ')[0]}
                          </Allergen>
                        )
                      )}
                    </NameAndAllergenWrapper>
                    <Price>{dish.price}</Price>
                  </Dish>
                ))}
              </Dishes>
            </DishesContainer>
          ))}
      </ColumnOne>

      <ColumnTwo>
        {menulistTwo &&
          menulistTwo.map((menu, i) => (
            <DishesContainer key={i}>
              <HeaderH3>{menu[`heading${lang}`]}</HeaderH3>
              <Dishes>
                {menu.menuItems.map((dish, dishIndex) => (
                  <Dish key={dishIndex}>
                    <NameAndAllergenWrapper>
                      <span>{dish[`name${lang}`]}</span>
                      {dish[`allergens${lang}`].map(
                        (allergen, allergenIndex) => (
                          <Allergen key={allergenIndex}>
                            {allergen.split(' ')[0]}
                          </Allergen>
                        )
                      )}
                    </NameAndAllergenWrapper>
                    <Price>{dish.price}</Price>
                  </Dish>
                ))}
              </Dishes>
            </DishesContainer>
          ))}
      </ColumnTwo>
    </MenuListContainer>
  );
}
const ColumnOne = styled.div`
  margin-bottom: 2.4rem;
`;

const ColumnTwo = styled.div`
  margin-bottom: 2.4rem;
`;

const DishesContainer = styled.div`
  margin-bottom: 2.4rem;

  padding: 0 2rem;

  @media (min-width: 1024px) {
    padding: 0;
  }
`;

const NameAndAllergenWrapper = styled.div`
  max-width: 358px;

  @media print {
    font-size: 1.8rem;
    max-width: 400px;
  }
`;

const DishName = styled.span``;

const Allergen = styled.span`
  font-family: 'Gineto-Italic';
  font-size: 1.2rem;
  margin-left: 0.5rem;

  @media print {
    min-width: 700px;
    font-size: 1.4rem;
  }
`;

export const MenuListContainer = styled.div`
  margin-bottom: 5.1rem;

  @media (min-width: 768px) {
    margin-bottom: 5.9rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 4rem;
    grid-row-gap: 0rem;

    @media print {
      margin-bottom: 2rem;
    }
  }
`;

const Price = styled.span`
  @media print {
    font-size: 1.8rem;
  }
`;

const Dishes = styled.ul`
  margin: 0;
  padding: 0;
`;

const Dish = styled.li`
  padding-bottom: 0.6rem;
  padding-top: 0.6rem;
  display: flex;
  align-items: flex-start;
  min-height: 30px;
  justify-content: space-between;
  list-style: none;
  border-bottom: 1px dashed var(--black);
`;
