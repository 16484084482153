import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { getImage } from 'gatsby-plugin-image';
import SanityBlockContent from '@sanity/block-content-to-react';
import { container, item } from '../../../utils/framer-motion-config';
import { serializers } from '../../../utils/serializers';
import { openingHoursText } from '../../../utils/staticText';

import { ImageContainer } from '../../shared/ImageContainer';
import { PageContainer } from '../../shared/PageContainer';
import { CustomGatsbyImage, HeroImage } from '../../shared/HeroImage';
import {
  Content,
  ImageAndTextBlock,
  Img,
} from '../../shared/ImageAndTextBlock';
import { ContentGrid } from '../../shared/ContentGrid';

export default function HomeContent({ sanityContent, openingHours, lang }) {
  const sanityBlockContentOne = sanityContent.contentSections[0];
  const sanityBlockContentTwo = sanityContent.contentSections[1];

  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    if (ref && isOpen !== false) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      });
    }
  }, [isOpen]);

  const staticTextLang = `${lang === 'No' ? 'no' : 'en'}`;

  return (
    <PageContainer>
      <HomeContentGrid variants={container} initial="hidden" animate="show">
        <ImageContainer variants={item}>
          <HeroImage image={getImage(sanityContent.mainImage.asset)} alt={sanityContent.mainImage.alt} />
        </ImageContainer>

        <Accordion>
          <AccordionItem>
            <AccordionTitle onClick={() => setIsOpen(!isOpen)} ref={ref}>
              <div style={{ display: "flex", alignItems: "center" }}>
                {openingHoursText.openingHours[staticTextLang]}
                {isOpen ? " ↑" : " ↓"}
              </div>
            </AccordionTitle>
            {isOpen && (
              <AccordionContent>
                {openingHours && (
                  <OpeningHours>
                    <SanityBlockContent
                      blocks={openingHours[`_rawText${lang}`]}
                      serializers={serializers}
                      projectId={process.env.SANITY_PROJECT_ID}
                      dataset={process.env.SANITY_DATASET}
                    />
                  </OpeningHours>
                )}

              </AccordionContent>
            )}
          </AccordionItem>
        </Accordion>

        <BigTextBlock variants={item}>
          <SanityBlockContent
            blocks={sanityContent.description[`_rawText${lang}`]}
            serializers={serializers}
            projectId={process.env.SANITY_PROJECT_ID}
            dataset={process.env.SANITY_DATASET}
          />
        </BigTextBlock>

        {sanityBlockContentOne && (
          <ImageAndTextBlockContainerOne
            image={sanityBlockContentOne.image.asset}
            altText={sanityBlockContentOne.image.altText}
            title={sanityBlockContentOne[`title${lang}`]}
            text={sanityBlockContentOne[`description${lang}`]}
            linkText={sanityBlockContentOne?.cta?.[`cta${lang}`]}
            linkTo={sanityBlockContentOne?.cta?.link?.href}
            internalLink
        />
        )}

    {sanityBlockContentTwo && (
          <ImageAndTextBlockContainerTwo
            image={sanityBlockContentTwo.image.asset}
            altText={sanityBlockContentTwo.image.altText}
            title={sanityBlockContentTwo[`title${lang}`]}
            text={sanityBlockContentTwo[`description${lang}`]}
            linkText={sanityBlockContentTwo?.cta?.[`cta${lang}`]}
            linkTo={sanityBlockContentTwo?.cta?.link?.href}
          />
        )}

        {sanityContent.footerImage && (
          <ImageContainerRestaurant>
            <CustomGatsbyImage image={getImage(sanityContent.footerImage.asset)} alt={sanityContent.footerImage.alt} />
          </ImageContainerRestaurant>
        )}
      </HomeContentGrid>
    </PageContainer>
  );
}

const OpeningHours = styled.div`
  p {
    font-size: 1.8rem;
    text-align: start;
    margin-bottom: 0;
    line-height: 2.5rem;
  }
`;

const BigTextBlock = styled.div`
padding: 0 2rem;

@media (min-width: 768px) {
    padding: 0;
  }
`;

const Accordion = styled.div`
  margin-bottom: 3rem;

  @media (min-width: 1024px) {
    margin-bottom: 7rem;
  }
`;

const AccordionItem = styled.div``;

const AccordionContent = styled.div`
  background-color: var(--klementin);
  padding: 0 2.5rem 2.5rem 2.5rem;
  height: 100%;
  transition: all 0.4s ease-in-out;

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  li {
    line-height: 2.5rem;
  }

  & li:first-child {
    max-width: 188px;
  }

  @media (min-width: 768px) {
    & li:first-child {
      max-width: 100%;
    }
  }
`;

const AccordionTitle = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  background-color: var(--klementin);
  padding: 2.5rem;
`;

const OpeningHoursLight = styled.div`
  height: 1.6rem;
  width: 1.6rem;
  border-radius: 50%;
  margin-right: 9px;
  background-color: var(--brown);
`;

const ImageContainerRestaurant = styled(ImageContainer)``;

const ImageAndTextBlockContainerOne = styled(ImageAndTextBlock)``;

const ImageAndTextBlockContainerTwo = styled(ImageAndTextBlock)`
  @media (min-width: 1024px) {
    margin-bottom: 15rem;
  }
`;

const HomeContentGrid = styled(ContentGrid)`
  margin: 0 auto;

  ${ImageContainer} {
    grid-column: 1 / 12;
    transition: all 0.4s ease-in-out;
  }

  ${Accordion} {
    grid-column: 1 / 12;
    transition: all 0.4s ease-in-out;
  }

  ${ImageContainerRestaurant} {
    grid-column: 1 / 12;
    transition: all 0.4s ease-in-out;
  }

  ${ImageAndTextBlockContainerOne} {
    grid-column: 2 / 11;
  }

  ${ImageAndTextBlockContainerTwo} {
    grid-column: 2 / 11;
  }

  ${BigTextBlock} {
    grid-column: 2 / 11;
    margin-bottom: 2rem;
  }

  @media (min-width: 768px) {
    ${ImageAndTextBlockContainerOne} {
      grid-column: 2 / 6;

      ${Img} {
        order: 1;
        margin-bottom: 11rem;
      }

      ${Content} {
        order: 2;
      }
    }

    ${ImageAndTextBlockContainerTwo} {
      grid-column: 7 / 11;

      ${Img} {
        order: 2;
      }

      ${Content} {
        order: 1;
        margin-bottom: 11rem;
      }
    }
  }

  @media (min-width: 1024px) {
    ${Accordion} {
      grid-column: 1 / -1;
    }

    ${ImageContainer} {
      grid-column: 1 / -1;
    }

    ${BigTextBlock} {
      grid-column: 1 / -1;
      text-align: center;
      max-width: 847px;
      margin: 0 auto 9rem auto;
    }

    ${ImageAndTextBlockContainerOne} {
      grid-column: 2 / 7;
    }

    ${ImageAndTextBlockContainerTwo} {
      grid-column: 7 / 12;
    }

    ${ImageContainerRestaurant} {
      grid-column: 1 / -1;
    }
  }
`;
