import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { motion } from 'framer-motion';
import { Header } from './Header';
import { Paragraph } from './Paragraph';

export const TextBlock = ({
  title,
  text,
  linktext,
  center,
  linkTo,
  big,
  bigPaddingBottom,
  noMargin,
  children,
  className,
  variants,
}) => (
  <TextBlockStyles
    className={className}
    center={center}
    bigPaddingBottom={bigPaddingBottom}
    variants={variants}
  >
    {title && <Header>{title}</Header>}
    {text && (
      <Paragraph big={big} noMargin={noMargin}>
        {text}
      </Paragraph>
    )}
    {linktext && <LinkStyles to={linkTo}>{linktext}</LinkStyles>}
    {children}
  </TextBlockStyles>
);

const TextBlockStyles = styled(motion.div)`
  text-align: ${(props) => (props.center ? 'center' : 'unset')};
  padding: 0 2rem;

  @media (min-width: 1024px) {
    padding: 0;
  }
`;

const LinkStyles = styled(Link)`
  font-weight: bold;

  &:hover {
    text-decoration: none;
  }

  color: var(--safran);

  &:after {
    content: '↗︎';
    margin-left: 0.4rem;
  }
`;

export const BigTextBlock = styled(TextBlock)`
  margin-bottom: 4.4rem;

  @media print {
    display: none;
  }
`;
