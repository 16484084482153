import React from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { HeaderH3 } from './Header';
import { Paragraph } from './Paragraph';

export const ImageAndTextBlock = ({
  image,
  altText,
  title,
  text,
  linkText,
  linkTo,
  contentOrder,
  gridColumn,
  gridRow,
  internalLink,
  className,
}) => (
  <ImageAndTextBlockContainer
    className={className}
    gridColumn={gridColumn}
    gridRow={gridRow}
  >
    <ImageAndTextBlockStyles>
      {image && <Img image={getImage(image)} alt={altText || ''} />}

      <Content contentOrder={contentOrder}>
        {title && <HeaderH3>{title}</HeaderH3>}
        {text && <Paragraph>{text}</Paragraph>}
        {internalLink ? (
          linkText && <InternalLink to={linkTo}>{linkText}</InternalLink>
        ) : (
          linkTo && <OutGoingLink href={linkTo} target="_blank" rel="noopener noreferrer">
            {linkText}
          </OutGoingLink>
        )}
      </Content>
    </ImageAndTextBlockStyles>
  </ImageAndTextBlockContainer>
);

const OutGoingLink = styled.a`
  color: var(--safran);
  border-bottom: 2px solid var(--safran);
  text-decoration: none;
  font-family: 'Gineto-Italic';
  display: inline-block;

  @media (min-width: 768px) {
    margin-bottom: ${(props) => (props.imageOrder === 2 ? '9rem' : '0')};
  }

  &:hover {
    text-decoration: none;
  }

  &:after {
    content: '→';
    margin-left: 0.4rem;
  }
`;

const InternalLink = styled(Link)`
  color: var(--safran);
  border-bottom: 2px solid var(--safran);
  text-decoration: none;
  font-family: 'Gineto-Italic';
  display: inline-block;

  @media (min-width: 768px) {
    margin-bottom: ${(props) => (props.imageOrder === 2 ? '9rem' : '0')};
  }

  &:hover {
    text-decoration: none;
  }

  &:after {
    content: '→';
    margin-left: 0.4rem;
  }
`;

export const ImageAndTextBlockContainer = styled.div`
  margin-bottom: 5.8rem;
  grid-column: ${(props) => props.gridColumn};
  grid-row: ${(props) => props.gridRow};
`;

const ImageAndTextBlockStyles = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  padding: 0 2.4rem;
@media (min-width: 768px) {
    padding: 0;
    order: ${(props) => props.contentOrder};
  }
`;

export const Img = styled(GatsbyImage)`
  object-fit: cover;
  width: 100%;
  height: auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  max-height: 800px;
  margin-bottom: 2.4rem;

  @media (min-width: 768px) {
    order: ${(props) => props.imageOrder};
    margin-bottom: ${(props) => (props.imageOrder === 1 ? '10.4rem' : '0')};
  }
`;
