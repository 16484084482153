import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getImage } from 'gatsby-plugin-image';

import SanityBlockContent from '@sanity/block-content-to-react';
import { Helmet } from 'react-helmet';
import { container, item } from '../../../utils/framer-motion-config';

import { ContentGrid } from '../../shared/ContentGrid';
import { Header, HeaderStyles } from '../../shared/Header';
import { HeroImage } from '../../shared/HeroImage';
import { PageContainer } from '../../shared/PageContainer';
import { ImageContainer } from '../../shared/ImageContainer';
import { BigTextBlock, TextBlock } from '../../shared/TextBlock';
import MenuList, { MenuListContainer } from '../../MenuList';
import { serializers } from '../../../utils/serializers';
import OgImage from '../../../assets/images/Avalon-og-image.jpg';

export default function LunchContent({ sanityContent, lang }) {
  const menulist = sanityContent.contentSections;

  const [allergensInMenu, setAllergensInMenu] = useState([]);

  useEffect(() => {
    const dishes = [];
    const allergens = [];

    // Adding the two menu columns
    const categories = menulist[0]
      ? menulist[0].menuSectionsOne.concat(menulist[0].menuSectionsTwo)
      : [];

    // Adding all the dishes
    categories.map((menuItem) => dishes.push(...menuItem.menuItems));

    // Adding all the allergens
    dishes.map((dish) => allergens.push(...dish[`allergens${lang}`]));

    // Checking for uniq allergens
    const uniqAllergens = [...new Set(allergens)];
    setAllergensInMenu(uniqAllergens.join(', '));
  }, [menulist]);

  return (
    <PageContainer>
      <Helmet>
        <meta charSet="utf-8" />
        <meta property="og:image" content={OgImage} />
        <meta
          property="og:title"
          content={`${sanityContent[`title${lang}`]} - Avalon`}
        />
        <meta
          property="og:description"
          content="Avalon er et moderne europeisk spisested som åpner i Oslo i november 2021"
        />
        <meta property="og:site_name" content="Avalon" />
      </Helmet>
      <LunchContentGrid variants={container} initial="hidden" animate="show">
        <Header center>{sanityContent[`title${lang}`]}</Header>

        {sanityContent.mainImage && (
          <ImageContainer variants={item}>
            <DinnerHeroImage
              image={getImage(sanityContent.mainImage.asset)}
              alt={sanityContent.mainImage.alt}
            />
          </ImageContainer>
        )}

        {sanityContent.description && (
          <BigTextBlock variants={item}>
            <SanityBlockContent
              blocks={sanityContent.description[`_rawText${lang}`]}
              serializers={serializers}
              projectId={process.env.SANITY_PROJECT_ID}
              dataset={process.env.SANITY_DATASET}
            />
          </BigTextBlock>
        )}

        <MenuList menulist={menulist} lang={lang} />
        <Allergies center>Allergener: {allergensInMenu}</Allergies>
      </LunchContentGrid>
    </PageContainer>
  );
}

const DinnerHeroImage = styled(HeroImage)`
  margin-bottom: 3rem;

  @media (min-width: 1024px) {
    margin-bottom: 7rem;
  }
`;

const Allergies = styled(TextBlock)`
  font-family: 'Gineto-Italic';
  line-height: 2.6rem;
  font-size: 1.8rem;
`;

const LunchContentGrid = styled(ContentGrid)`
  ${HeaderStyles} {
    grid-column: 1 / 12;
  }

  ${MenuListContainer} {
    grid-column: 2 / 11;
  }

  ${ImageContainer} {
    grid-column: 1 / 12;
    transition: all 0.4s ease-in-out;
  }

  ${BigTextBlock} {
    grid-column: 2 / 11;
  }

  ${Allergies} {
    grid-column: 1 / 12;
    margin-bottom: 2rem;
    margin: 0 auto 1.5rem auto;
  }

  @media (min-width: 768px) {
    ${Allergies} {
      max-width: 555px;
      @media print {
        max-width: 730px;
      }
    }
  }

  @media (min-width: 1024px) {
    margin: 0 auto 10rem auto;

    ${ImageContainer} {
      grid-column: 1 / -1;
    }

    ${MenuListContainer} {
      grid-column: 2 / 12;

      @media print {
        grid-column: 1 / 13;
      }
    }

    ${HeaderStyles} {
      grid-column: 1 / -1;
    }

    ${Allergies} {
      grid-column: 2 / 12;
      max-width: 730px;
    }

    ${BigTextBlock} {
      grid-column: 1 / -1;
      text-align: center;
      max-width: 847px;
      margin: 0 auto 7rem auto;
    }
  }
`;
