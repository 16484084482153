import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import HomeContent from '../components/ContentPages/HomeContent';
import LunchContent from '../components/ContentPages/LunchContent';

function SundayPage({ data, location }) {
  const sanityContent = data.sundaypage;

  return (
    <Layout location={location} lang="en">
      <LunchContent sanityContent={sanityContent} lang="En" />
    </Layout>
  );
}

export const query = graphql`
  query SundayPageEn {
    sundaypage: sanityPage(id: { eq: "-7f5db90f-bc71-5a63-85bd-07bdb124f369" }) {
      id
      titleEn
      description {
        _rawTextEn
      }
      mainImage {
        asset {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
        alt
      }
      contentSections {
        ... on SanityMenu {
          id
          menuSectionsOne {
            headingEn
            menuItems {
              descriptionEn
              nameEn
              price
              allergensEn
              hidden
            }
          }
          menuSectionsTwo {
            headingEn
            menuItems {
              descriptionEn
              nameEn
              price
              allergensEn
              hidden
            }
          }
        }
      }
    }
  }
`;

export default SundayPage;
